/* global document window */
// @flow
import { FETCH_COMMENTS, SUBMIT_NEW_COMMENT_MUTATION, SUBMIT_COMMENT_NOTIFICATION_EMAIL_MUTATION, SUBMIT_NEW_VOTE_MUTATION, REPORT_ABUSE_MUTATION, } from '@haaretz/graphql';
import { UserTypes, } from '@haaretz/htz-user-utils';
import config from 'config';
import React, { useState, } from 'react';
import { useMutation, } from 'react-apollo';

import { useFela, } from 'react-fela';
import { useInView, } from 'react-intersection-observer';
import { useRouter, } from 'next/router';
import useGetComponent from '../../hooks/GetComponentContext/useGetComponent';
import useArticleData from '../../hooks/Page/useArticleData';
import useArticleId from '../../hooks/Page/useArticleId';
import useWebViewChecker from '../../hooks/useWebViewChecker';
import usePreview from '../../hooks/Page/usePreview';
import { useEventTracker, } from '../../utils/EventTracker';
import Query from '../ApolloBoundary/Query';
import GetUserNickName from '../User/GetUserNickName';
import { useUser, } from '../User/UserDispenser';
import CommentForm from './CommentForm';
import { OrderCommentsProvider, useOrderComments, } from './CommentOrderProvider';
import CommentPaywall from './CommentPaywall';
import CommentSection from './CommentsSection';
import {
  type SubmitLikeDislikeCommentParams, type SubmitNewCommentParams,
} from './utils/comment';

export type CommentsBannerProps = {
  bannerAction: string,
  bannerHeader: string,
  bannerText: Object[],
}

type CommentsWithApolloProps = {
  inView: boolean,
  bannerData: CommentsBannerProps,
}

const { disallowedUserTypes, enableUserNickName, } = config.get('comments');
// number of additional comments to render each time load more button is clicked
const commentPaginationCount = 100;
const initialMaxCommentsToRender = 10;

function CommentsWithApollo({ inView, bannerData, }: CommentsWithApolloProps) {
  const getComponent = useGetComponent();
  const LoaderIcon = getComponent('loaderIcon');
  const { user, } = useUser();
  const userType: string = user?.type || UserTypes.anonymous;
  const disableUserComment = disallowedUserTypes.includes(userType);
  const isWebView = useWebViewChecker();
  const { isPreview, } = usePreview();
  const { sortMethod, } = useOrderComments();
  const articleData = useArticleData();

  const router = useRouter();

  const singleCommentId = router.query.commentId;
  const subSingleCommentId = router.query.subcommentId;

  const { biImpression, biAction, } = useEventTracker();

  const [ submitNewComment, ] = useMutation(SUBMIT_NEW_COMMENT_MUTATION);
  const [ submitNotificationEmail, ] = useMutation(SUBMIT_COMMENT_NOTIFICATION_EMAIL_MUTATION);
  const [ submitNewVote, ] = useMutation(SUBMIT_NEW_VOTE_MUTATION);
  const [ reportAbuse, ] = useMutation(REPORT_ABUSE_MUTATION);

  const { commentsElementId: contentId, } = articleData || {};

  const [ newCommentId, setNewCommentId, ] = useState(null);

  const { css, theme, } = useFela();

  const { commentsElementId, } = articleData || {};

  const initVote = (params: SubmitLikeDislikeCommentParams, voteFn: typeof submitNewVote) => {
    voteFn({
      variables: {
        input: {
          ...params,
        },
      },
    })
      .catch(mutationError => {
        console.warn('there was an error sending the query', mutationError);
      });
  };

  const initNewComment = (params: SubmitNewCommentParams, submitFn: typeof submitNewComment) => submitFn({
    variables: {
      input: {
        ...params,
      },
    }, })
    .then(({ data, }) => {
      const { errorCode, status, } = data?.submitNewComment || {};

      if (status !== 'ok') {
        throw Error(`${errorCode ? `${errorCode} - ` : ''}Comment creation failed`);
      }

      setNewCommentId(data.submitNewComment.commentId);
    });

  const initSignUpNotificationEmail = (authorEmail: string, fn: typeof submitNotificationEmail) => {
    fn({
      variables: {
        input: {
          authorEmail,
          commentId: newCommentId || '',
        },
      },
    });

    setNewCommentId(undefined);
  };

  const initReportAbuse = (commentId: string, captchaKey: string, fn: typeof reportAbuse) => (
    fn({
      variables: {
        input: {
          commentId,
          captchaKey,
          commentElementId: contentId,
          // cookie: SSOCookie,
        },
      },
    })
      .then(({ data, }) => ({ reportSuccess: data?.reportAbuse?.reportSuccess || data?.reportAbuse?.status === 'ok', }))
      .catch(err => ({ reportSuccess: false, }))
  );

  const handleLoadMoreComments = (fetchMore, lastCommentId) => {
    fetchMore({
      variables: {
        cursor: lastCommentId,
      },
      updateQuery: (prevResult, { fetchMoreResult, }) => {
        if (!fetchMoreResult) return prevResult;

        const comments = ({
          ...prevResult,
          ...fetchMoreResult,
          CommentsElement: {
            ...prevResult.CommentsElement,
            ...fetchMoreResult.CommentsElement,
            comments: [
              ...prevResult.CommentsElement.comments,
              ...fetchMoreResult.CommentsElement.comments,
            ],
          },
        });

        return comments;
      },
    });
  };

  React.useEffect(() => {
    const comment = document.getElementById(subSingleCommentId);
    if (comment) {
      comment.scrollIntoView({
        behavior: 'auto',
        block: 'center',
      });
    }
    return undefined;
  }, [ singleCommentId, isWebView, subSingleCommentId, ]);

  return (
    <div
      className={css({
        marginTop: '2rem',
        color: theme.color('bodyText', 'base'),
      })}
    >
      {enableUserNickName && inView && userType === 'paying' ? <GetUserNickName /> : null}
      {disableUserComment && inView
        ? userType === 'paying' || isWebView || isPreview
          ? null : <CommentPaywall biAction={biAction} biImpression={biImpression} bannerData={bannerData} />
        : (
          <CommentForm
            initNewComment={(params: SubmitNewCommentParams) => initNewComment(params, submitNewComment)}
            signUpNotification={email => initSignUpNotificationEmail(email, submitNotificationEmail)}
            userType={userType}
            enableNickname={enableUserNickName}
          />
        )}
      {/* Fetch Comments only when in root margin view  or it is a anchored comment link by mail */}
      {inView ? (
        <Query
          query={FETCH_COMMENTS}
          variables={{
            model: { id: commentsElementId, },
            sort: sortMethod?.value || 'firstToLast',
            count: commentPaginationCount,
            cursor: '',
          }}
        >
          {({ data, loading, error, fetchMore, /* client, */ }) => {
            if (loading) {
              return (
                <LoaderIcon
                  size={15}
                  color="primary"
                  miscStyles={{
                    marginTop: '5rem',
                    marginBottom: '5rem',
                    marginStart: 'auto',
                    marginEnd: 'auto',
                    display: 'block',
                  }}
                />
              );
            }
            if (error) {
              return null;
            }
            if (!data) return null;
            const { CommentsElement, } = data;
            const lastCommentId = (CommentsElement?.comments || []).slice(-1)?.[0]?.contentId;

            return (
              <Query
                query={FETCH_COMMENTS}
                skip={!singleCommentId}
                variables={{
                  model: { id: articleData?.commentsElementId, },
                  commentId: singleCommentId,
                  sort: sortMethod?.value || 'firstToLast',
                  count: commentPaginationCount,
                  cursor: '',
                }}
              >
                {({ data: oneCommentData, }) => {
                  const singleCommentData = Array.isArray(oneCommentData?.CommentsElement?.comments) && oneCommentData.CommentsElement.comments.length > 0 ? oneCommentData.CommentsElement.comments : null;

                  return (
                    <CommentSection
                      initNewComment={(params: SubmitNewCommentParams) => initNewComment(params, submitNewComment)}
                      initVote={params => {
                        initVote(params, submitNewVote);
                      }}
                      reportAbuse={(commentId, captchaKey) => (
                        initReportAbuse(commentId, captchaKey, reportAbuse)
                      )}
                      signUpNotification={email => (
                        initSignUpNotificationEmail(email, submitNotificationEmail)
                      )}
                      loadMoreComments={() => handleLoadMoreComments(fetchMore, lastCommentId)}
                      comments={CommentsElement ? CommentsElement.comments : []}
                      singleComment={singleCommentData}
                      totalHits={CommentsElement ? CommentsElement.totalHits : 0}
                      userType={userType}
                      disableReply={disableUserComment}
                      enableNickname={enableUserNickName}
                      commentPaginationCount={commentPaginationCount}
                      subSingleCommentId={subSingleCommentId}
                      initialMaxCommentsToRender={initialMaxCommentsToRender}
                      bannersInterval={CommentsElement?.bannersInterval}
                      banners={CommentsElement?.banners}
                    />
                  );
                }}
              </Query>
            );
          }}
        </Query>
      ) : (
        <LoaderIcon
          size={10}
          color="primary"
          miscStyles={{
            marginTop: '5rem',
            marginBottom: '5rem',
            marginStart: 'auto',
            marginEnd: 'auto',
            display: 'block',
          }}
        />
      )}
    </div>
  );
}

CommentsWithApollo.defaultProps = {
  inView: false,
  bannerData: null,
};


function WrappedComments({ bannerHeader, bannerText, bannerAction, }: CommentsBannerProps) {
  const articleId = useArticleId();
  const router = useRouter();

  const singleCommentId = router.query.commentId;
  const [ ref, inView, ] = useInView({ rootMargin: '2000px', triggerOnce: true, });

  return (
    <OrderCommentsProvider>
      {articleId ? (
        <div ref={ref}>
          <CommentsWithApollo
            inView={inView || !!singleCommentId}
            bannerData={{ bannerHeader, bannerText, bannerAction, }}
          />
        </div>
      ) : null}
    </OrderCommentsProvider>
  );
}

export default WrappedComments;
